@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Poppins:wght@400;500&display=swap');
:root {
  --primary: #bc4b51;
  --secondary: #297373;
  --background: #f5f5f5;
  --text: #17183b;
  --black: #0f0e0e;
  --gray: #b8c4bb;
  --container-padding: 1rem;
  --container-padding-lg: 1.5rem;
  --font-serif: 'Lora', Georgia, 'Times New Roman', Times, serif;
  --font-sans-serif: 'Poppins', Arial, Helvetica, sans-serif;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html {
  box-sizing: border-box;
  font-size: 16px;
  // scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
}

body {
  font-family: var(--font-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--background);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 20px;
  border: 3px solid var(--secondary);
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}

#wrapper {
  background-color: var(--background);
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}

.wrapper-inner {
  width: 80%;
  margin: 0 auto;
}

#header {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  &::after,
  &::before {
    height: 70%;
    width: 50%;
    content: '';
    // background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: -1;
    background: url('../assets/images/Oreti.svg');
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.25;
  }

  &::after {
    right: 0;
    bottom: 0;
    // border: solid 1px orange;
    transform: rotate(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
}

.navbar {
  // position: fixed;
  // width: 100%;
  // left: 0;
  z-index: 6;
  // background-color: var(--background);
  // border: solid 1px var(--secondary);

  .navbar-inner {
    // width: 80%;
    // margin: 0 auto;
    display: flex;
    z-index: 6;
    justify-content: space-between;
    // border: solid 1px green;
    align-items: center;
    padding: 0 var(--container-padding);
  }

  &__brand {
    height: 80px;
    width: 80px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__menu {
    list-style-type: none;
    display: none;
    gap: 0.75rem 1.25rem;

    &__item {
      &__link {
        font-size: 1rem;
        color: var(--text);
        text-transform: uppercase;
        font-weight: 400;
        display: inline-block;
        position: relative;
        // letter-spacing: 0.25px;

        &:hover {
          color: var(--secondary);
        }

        &::after {
          width: 100%;
          border-bottom: solid 2px transparent;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &.active {
          color: var(--secondary);
          font-weight: 500;
        }

        &.active,
        &:hover {
          &::after {
            border-bottom-color: var(--secondary);
          }
        }
      }
    }
  }
}

.hero {
  // border: solid 1px var(--secondary);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--container-padding);
  // margin-top: 90px;
  gap: 1rem 0;
  // border: solid 1px green;

  &__heading {
    font-size: 3.125rem;
    line-height: 1.1;
    font-family: var(--font-serif);
    text-align: center;

    &__start {
      display: block;
    }
  }

  &__description {
    color: var(--secondary);
    font-weight: 500;
    font-size: 1.5rem;
  }

  &__btn-group {
    display: flex;
    gap: 0.75rem;
    margin-top: 0.5rem;

    .btn {
      width: 200px;
      border-radius: 5px;
    }
  }
}

.social {
  padding: var(--container-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  flex-wrap: wrap;
  // border: solid 1px yellow;

  &__list {
    display: flex;
    list-style-type: none;
    gap: 0.5rem;
    align-items: center;

    &__item {
      figure {
        height: 30px;
        width: 30px;
        border-radius: 8px;
        background-color: var(--text);
        overflow: hidden;
        // padding: 0.25rem;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem;
          // border: solid 1px purple;
          height: 100%;
          // width: 100%;

          &:hover {
            opacity: 0.75;
          }
        }

        img {
          height: 17px;
          width: 17px;
        }
      }
    }
  }
}
.btn {
  padding: 0.75rem 1rem;
  border-style: solid;
  border-width: 1px;
  min-width: 100px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.5s, color 0.5s;
  cursor: pointer;

  &.btn--secondary {
    border-color: var(--secondary);
    color: var(--secondary);

    &:hover {
      background-color: var(--secondary);
      color: var(--background);
    }
  }
}

// services
#services {
  display: none;
  .services-inner {
    .placard {
      gap: 1rem;
      align-items: center;
      text-align: center;
    }
  }
}

// projects
#projects {
  .projects-inner {
    .placard {
      // border: solid 1px var(--text);
      border-radius: 8px;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .section-grid {
      gap: 2rem 1.5rem;
    }
  }
}

.section-inner {
  padding: var(--container-padding-lg) var(--container-padding);
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.section-heading {
  font-size: 1.75rem;
  text-align: center;
  position: relative;
  z-index: 5;

  &::before {
    height: 40px;
    width: 40px;
    content: '';
    position: absolute;
    // left: 0;
    // top: 0;
    transform: translateX(-10px);
    border-radius: 30px;
    z-index: -1;
    display: inline-block;
    // opacity: 0.1;
    background-color: rgba($color: var(--secondary), $alpha: 0.1);
    background: url('../assets/images/dots.png') no-repeat;
    background-size: cover;
  }
}

.section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.placard {
  display: flex;
  flex-direction: column;
  // align-items: center;

  &__image {
    height: 50px;
    width: 50px;
    // background-color: var(--text);
    border-radius: 30px;

    img {
      // visibility: hidden;
      height: 100%;
      width: 100%;
    }
  }

  &__description {
    font-size: 0.85rem;
  }

  &__heading {
    color: var(--secondary);
  }

  &__header {
    &__image {
      height: 200px;
      overflow: hidden;
      position: relative;
      border-radius: 8px 8px 0 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }

      &::after {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // content: '';
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__body {
    padding: 0.75rem 0.75rem;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: flex-start;

    &__start,
    &__end {
      display: flex;
      flex-direction: column;
    }

    &__start {
      gap: 0.1rem;
      h6 {
        color: var(--secondary);
        font-weight: 700;
        // font-size: 0.7rem;
        letter-spacing: 0.15px;
      }
    }

    &__end {
      gap: 0.25rem;

      figure {
        height: 18px;
        width: 18px;
        overflow: hidden;
        padding: 0.1rem;
        // border: solid 1px var(--secondary);

        a {
          height: 100%;
          // border: solid 1px var(--secondary);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

// footer
footer {
  background-color: var(--text);
  .footer-inner {
    padding: var(--container-padding) var(--container-padding-lg);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
      color: var(--background);
      font-size: 0.8rem;
      text-align: center;
      font-weight: 500;

      a {
        color: var(--secondary);
        font-weight: 600;

        &:hover {
          text-decoration: underline;
          transition: 0.5s;
        }
      }
    }
  }
}

#contact {
  .contact-inner {
    &__box {
      display: flex;
      flex-wrap: wrap;

      &__start {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.75rem 0;

        p {
          font-size: 1.25rem;
          font-weight: 500;

          &:last-of-type {
            color: gray;
            font-size: 0.85rem;

            a {
              color: var(--secondary);

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.contact-form {
  flex: 2;
  display: flex;
  justify-content: center;
  width: 100%;

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: none;
    width: 100%;
  }

  &__bunk {
    display: flex;
    gap: 0.75rem;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
  }

  &__label,
  &__input {
    font-size: 0.85rem;
  }

  &__input {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 0.75rem;
    border: solid 1px transparent;
    outline: none;
    overflow: hidden;
    font-family: var(--font-sans-serif);
    // background-color: #f1f1f1;fieldset
    // background-color: var(--gr);
  }

  &__input,
  .btn {
    border-radius: 5px;
  }

  .btn {
    align-self: flex-end;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 576px) {
  #header {
    &::after,
    &::before {
      display: none;
    }
  }

  .wrapper-inner {
    width: 90%;
  }

  .navbar {
    flex-direction: column;
    gap: 0.75rem;
  }

  .hero {
    &__description {
      text-align: center;
      font-size: 1.25rem;
    }

    &__btn-group {
      flex-direction: column;
    }
  }

  .social {
    flex-direction: column;
    gap: 1rem;
  }

  .section-heading {
    font-size: 1.5rem;

    &::before {
      height: 35px;
      width: 35px;
    }
  }

  .contact-form {
    padding: 0 1rem;

    fieldset {
      padding: 0;
      margin-top: 0.5rem;
    }

    &__bunk {
      flex-direction: column;
    }

    &__label,
    &__input {
      font-size: 1rem;
    }

    .btn {
      align-self: unset;
    }
  }
}

@media only screen and (max-width: 768px) {
  #header {
    &::after,
    &::before {
      height: 100%;
      width: 100%;
    }
  }

  #contact {
    .contact-inner {
      &__box {
        margin-top: -10px;

        &__start {
          text-align: center;
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
